module.exports = [{
      plugin: require('../source-plugin/gatsby-browser.js'),
      options: {"plugins":[],"credentials":{"apiKey":"AIzaSyCOdEVSLwHozBlafQp0f8DbX_Pv_2P9IKk","authDomain":"ameliabakar-b63e6.firebaseapp.com","projectId":"ameliabakar-b63e6","storageBucket":"ameliabakar-b63e6.appspot.com","messagingSenderId":"381053915945","appId":"1:381053915945:web:36955d0bf1d5f387dedd38"}},
    },{
      plugin: require('../node_modules/gatsby-plugin-catch-links/gatsby-browser.js'),
      options: {"plugins":[]},
    },{
      plugin: require('../node_modules/gatsby-source-wordpress/gatsby-browser.js'),
      options: {"plugins":[],"url":"https://wp.ameliabakar.se/graphql","schema":{"timeout":9000000,"queryDepth":15,"circularQueryLimit":5,"typePrefix":"Wp","perPage":100,"requestConcurrency":15,"previewRequestConcurrency":5},"verbose":true,"catchLinks":true},
    },{
      plugin: require('../node_modules/gatsby-plugin-manifest/gatsby-browser.js'),
      options: {"plugins":[],"name":"Amelia bakar","short_name":"Amelia bakar","start_url":"/","background_color":"#ffffff","theme_color":"#C4C4BA","display":"minimal-ui","icon":"content/assets/favicon-32x32.png","legacy":true,"theme_color_in_head":true,"cache_busting_mode":"query","crossOrigin":"anonymous","include_favicon":true,"cacheDigest":"17b3f55b177d91ce93d94769a12f9564"},
    },{
      plugin: require('../gatsby-browser.jsx'),
      options: {"plugins":[]},
    },{
      plugin: require('../node_modules/gatsby/dist/internal-plugins/partytown/gatsby-browser.js'),
      options: {"plugins":[]},
    }]
