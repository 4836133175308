exports.components = {
  "component---src-pages-404-tsx": () => import("./../../../src/pages/404.tsx" /* webpackChunkName: "component---src-pages-404-tsx" */),
  "component---src-templates-about-me-tsx": () => import("./../../../src/templates/aboutMe.tsx" /* webpackChunkName: "component---src-templates-about-me-tsx" */),
  "component---src-templates-home-tsx": () => import("./../../../src/templates/home.tsx" /* webpackChunkName: "component---src-templates-home-tsx" */),
  "component---src-templates-orders-tsx": () => import("./../../../src/templates/orders.tsx" /* webpackChunkName: "component---src-templates-orders-tsx" */),
  "component---src-templates-recept-post-tsx": () => import("./../../../src/templates/receptPost.tsx" /* webpackChunkName: "component---src-templates-recept-post-tsx" */),
  "component---src-templates-recept-tsx": () => import("./../../../src/templates/recept.tsx" /* webpackChunkName: "component---src-templates-recept-tsx" */),
  "component---src-templates-saved-recipes-tsx": () => import("./../../../src/templates/savedRecipes.tsx" /* webpackChunkName: "component---src-templates-saved-recipes-tsx" */),
  "component---src-templates-tips-tsx": () => import("./../../../src/templates/tips.tsx" /* webpackChunkName: "component---src-templates-tips-tsx" */)
}

